<script setup lang='ts'>
// This was going to have the ability to switch to next/previous videos, but the designer took it out.

// 1. imports
import type { CreateTrainingVideoSessionRequest } from '~/types/api/Psrm.Mobile.Api.Contract.TrainingVideo';

export type VideoPlayerVideo = { title?: string; id?: string; uri?: string; required?: boolean; requiredIntervalDays?: number };

const { $trainingVideoService, $broadcastsHelper } = useNuxtApp();
const user = currentUser();
const updateInterval = 5;
let lastInterval = 0;

// 2. defineEmits
const $emit = defineEmits<{
    (e: 'complete', id: string): void;
    (e: 'hide'): void;
    (e: 'update:modelValue', v: number | null): void;
}>();

// 3. defineProps
const $props = defineProps<{
    ivrCallId: string | undefined;
    videos: VideoPlayerVideo[];
    modelValue: number | null;
}>();

// 4. fetchers
const createVideoSessionFetcher = $trainingVideoService.createTrainingVideoSessionPostFetcher();
const updateVideoProgressFetcher = $trainingVideoService.updateTrainingVideoSessionPostFetcher();

// 5.state variables
const componentState = reactive({
    show: false,
    paused: false,
    uuid: null as string | null,
    progressWidth: '0%',
    currentTimeCompleted: 0,
    seekDisabledWarning: false,
    sessionAvailable: true,
});

let currentTimeLastUpdated = Date.now();

const videoElement = ref<HTMLVideoElement>();

// 6. computed variables
const overlayOpacity = computed(() => {
    if (componentState.paused) {
        return 1.0;
    }
    if (videoElement.value && videoElement.value.currentTime === videoElement.value.duration) {
        return 1.0;
    }
    return 0.0;
});

const currentVideo = computed(() => {
    return $props.modelValue !== null ? $props.videos[$props.modelValue] : undefined;
});

const isWatched = computed(() => {
    if (currentVideo.value?.id) {
        return $broadcastsHelper.isAcknowledged(currentVideo.value.id, currentVideo.value.requiredIntervalDays);
    }
    return false;
});

const canClose = computed(() => {
    if (!currentVideo.value?.required) {
        return true;
    }
    return isWatched.value;
});

// 7. lifecycle hooks (onMounted)
onMounted(initOrUpdate);

// 9. watches
watch(() => $props.modelValue, initOrUpdate);

// 10. functions
function initOrUpdate() {
    componentState.show = $props.modelValue !== null;
    nextTick(() => {
        if (componentState.show && videoElement.value) {
            videoElement.value.play();
            componentState.paused = false;
            createTrainingVideoSession();
        }
    });
}

function onPlayPause() {
    if (!videoElement.value) {
        return;
    }
    if (user.pinInfo && !componentState.uuid) {
        createTrainingVideoSession();
    }

    componentState.paused = !videoElement.value.paused;
    if (videoElement.value.paused === true) {
        videoElement.value.play();
    }
    else {
        videoElement.value.pause();
        onVideoProgress();
    }
}

async function createTrainingVideoSession() {
    if (!user.pinInfo && !$props.ivrCallId) {
        return;
    }
    if (currentVideo.value === undefined) {
        return;
    }
    componentState.uuid = crypto.randomUUID();
    const createSessionParams: CreateTrainingVideoSessionRequest = {
        uuid: componentState.uuid,
        ivrCallId: $props.ivrCallId,
        pin: user.pinInfo?.workOrderPin,
        videoTitle: currentVideo.value.title,
    };
    try {
        componentState.sessionAvailable = true;
        await createVideoSessionFetcher.fetch(createSessionParams);
    }
    catch (e) {
        componentState.sessionAvailable = false;
        console.error(e);
    }
}

function onVideoProgress() {
    if (!videoElement.value) {
        return;
    }
    const updatedSecondsAgo = (Date.now() - currentTimeLastUpdated) / 1000;
    const timePassed = videoElement.value.currentTime - componentState.currentTimeCompleted;
    if (updatedSecondsAgo - timePassed < -0.5) {
        return;
    }
    componentState.currentTimeCompleted = Math.max(videoElement.value.currentTime, componentState.currentTimeCompleted);
    const percentageComplete = (videoElement.value.currentTime / videoElement.value.duration) * 100;

    const currentInterval = Math.floor(percentageComplete / updateInterval) * updateInterval;
    if (currentInterval > lastInterval) {
        updateVideoProgress(Math.floor(percentageComplete));
    }
    lastInterval = currentInterval;

    componentState.progressWidth = `${percentageComplete.toFixed(2)}%`;
    currentTimeLastUpdated = Date.now();
}

function onVideoSeek() {
    if (!videoElement.value) {
        return;
    }
    if (isWatched.value === true) {
        return;
    }
    if (videoElement.value.currentTime > componentState.currentTimeCompleted) {
        videoElement.value.currentTime = componentState.currentTimeCompleted;
        componentState.seekDisabledWarning = true;
    }
}

function onVideoEnded() {
    if (!currentVideo.value) {
        return;
    }

    componentState.progressWidth = '100%';
    $emit('complete', currentVideo.value.id!);
    componentState.seekDisabledWarning = false;
}

async function updateVideoProgress(percentageComplete: number) {
    if (!componentState.uuid || !componentState.sessionAvailable) {
        return;
    }
    const updateParams: Parameters<typeof updateVideoProgressFetcher.fetch>[0] = {
        uuid: componentState.uuid,
        percentageComplete,
    };
    try {
        await updateVideoProgressFetcher.fetch(updateParams);
    }
    catch (e) {
        console.error(e);
    }
}

function onHide() {
    $emit('update:modelValue', null);
    componentState.progressWidth = '0';
}
</script>

<template>
    <BModal
        v-model="componentState.show"
        data-bs-theme="dark"
        :title="currentVideo?.title ?? 'Video Player'"
        title-tag="h2"
        title-class="text-white h5"
        :hide-header-close="!canClose"
        @hide.prevent="onHide"
    >
        <div v-if="currentVideo?.required" class="text-light pb-3">
            <small>This is a required video, and you must watch to completion before continuing.</small>
        </div>
        <div
            class="video-container"
            @click="$event.stopPropagation()"
        >
            <video
                v-if="currentVideo"
                ref="videoElement"
                class=""
                x-webkit-airplay="deny"
                @click="onPlayPause"
                @timeupdate="onVideoProgress"
                @seeking="onVideoSeek"
                @ended="onVideoEnded"
            >
                <source type="video/mp4" :src="currentVideo.uri">
                Your browser is unable to play the video.
            </video>
            <div
                class="overlay play"
                :style="{ opacity: overlayOpacity }"
                @click="onPlayPause"
            >
                <FontAwesomeIcon icon="play" size="1x" />
            </div>
            <div class="progress-container" @click="onPlayPause">
                <div class="progress" :style="{ width: componentState.progressWidth }"></div>
            </div>
        </div>
        <template #footer>
            <span
                v-if="isWatched"
                class="me-auto badge badge-success"
            >
                <FontAwesomeIcon icon="circle-check" />
                Watched
            </span>
            <div
                v-if="!isWatched && componentState.seekDisabledWarning"
                class="w-100 bg-danger text-white p-2 rounded"
            >
                <FontAwesomeIcon icon="circle-xmark" />
                <strong>Seeking forward is not allowed.</strong>
            </div>
            <div v-else></div> <!-- Required to override buttons -->
        </template>
    </BModal>
</template>

<style scoped lang='scss'>
.video-container {
    position: relative;
    container-type: inline-size;

    video {
        position: relative;
        width: 100%;
    }

    .overlay {
        position: absolute;
        inset: 50% 0 0;
        transform: translateY(-50%);
        line-height: 1;
        display: block;
        color: var(--bs-gray-100);
        cursor: pointer;
        font-size: 25cqh;
        text-align: center;
        text-shadow: 0 0 10px #000;
        transition: 1s opacity;
        width: 100%;
    }

    .progress-container {
        background: var(--bs-gray-700);
        border-radius: 4px;
        inset: auto 0 0;
        height: 4px;
        position: absolute;

        .progress {
            background: var(--bs-gray-100);
            border-radius: 4px;
            height: 4px;
            width: 0;
        }
    }
}
</style>
